import React, { useContext, useEffect, useMemo, useState } from 'react'

import { Router } from '@reach/router'

import { NavFooter } from 'components/app/nav-footer'
import Header from 'components/app/project/header/header-basic'
import FormHeader from 'components/shared/header-form'
import useProspect from 'context/prospect/use'
import FlowLayout from 'design-system/containers/flow-layout'
import AffiliateBanner from 'views/welcome/prospect/affiliate-banner'

import {
  NO_PROSPECT_QUIZ_STEPS_ARRAY,
  PROSPECT_QUIZ_STEPS_ARRAY,
  ProspectFlowRouteProps,
} from './steps'
import { QuizContext } from '..'

const StartProspectFlow = ({
  path: _,
  ...routeProps
}: ProspectFlowRouteProps) => {
  const { '*': subroute, navigate } = routeProps
  const [loadingButton, setLoadingButton] = useState<boolean>(false)
  const [nextDisabled, setNextDisabled] = useState(true)
  const [onNext, setOnNext] = useState<() => Promise<void>>()
  const { prospect, prospectUrlComponent } = useProspect()
  const { affiliateCode, navigateWithQS } = useContext(QuizContext)

  const steps = prospect
    ? PROSPECT_QUIZ_STEPS_ARRAY
    : NO_PROSPECT_QUIZ_STEPS_ARRAY

  const currentStepIndex = useMemo(
    () => steps.findIndex((step) => step.subroute === subroute),
    [subroute],
  )

  useEffect(() => {
    navigateWithQS(
      `/welcome/${prospectUrlComponent}/quiz/project-info/your-style`,
    )
  }, [])

  const isFirstStep = currentStepIndex <= 0
  const isLastStep = currentStepIndex >= steps.length - 1

  const handleNext = async () => {
    await onNext?.()
    if (!isLastStep)
      navigateWithQS(
        `/welcome/${prospectUrlComponent}/quiz/project-info/${
          steps[currentStepIndex + 1].subroute
        }`,
      )
  }

  return (
    <FlowLayout
      header={
        prospect?.id ? (
          <Header onBack={() => navigate?.(`/welcome/${prospect.id}`)} />
        ) : (
          <FormHeader />
        )
      }
      main={
        <>
          <AffiliateBanner affiliateCode={affiliateCode} />
          <Router>
            {steps.map(({ View, subroute }) => (
              <View
                key={subroute}
                path={subroute}
                setLoading={setLoadingButton}
                setOnNext={setOnNext as () => Promise<void>}
                setNextDisabled={setNextDisabled}
              />
            ))}
          </Router>
        </>
      }
      actions={
        <NavFooter
          hideBack={isFirstStep}
          backDisabled={isFirstStep}
          nextDisabled={nextDisabled}
          loading={loadingButton}
          onBack={() =>
            navigateWithQS(
              `/welcome/${prospectUrlComponent}/quiz/project-info/${
                steps[currentStepIndex - 1].subroute
              }`,
            )
          }
          onNext={handleNext}
        />
      }
      stepperProps={{
        currentStep: currentStepIndex,
        steps,
      }}
    />
  )
}

export default StartProspectFlow
