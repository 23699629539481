import React from 'react'

import { useMedia } from 'react-use'
import { Image } from 'semantic-ui-react'
import styled from 'styled-components'

import { Breakpoint, Colors } from 'styles/app/system'

const DEFAULT_URLS = [
  `${process.env.GATSBY_CDN_ROOT}/images/app/designers/ladyline.webp`,
  `${process.env.GATSBY_CDN_ROOT}/images/app/designers/eva.webp`,
  `${process.env.GATSBY_CDN_ROOT}/images/app/designers/debora.webp`,
]

export const DesignersAvatars = ({
  urls = DEFAULT_URLS,
}: {
  urls?: string[]
}) => {
  return (
    <DesignersDiv>
      {(urls ?? DEFAULT_URLS).map((url, idx) => (
        <Image className="avatar" key={idx} src={url} />
      ))}
    </DesignersDiv>
  )
}

export const DesignersAvatarsHeader = ({
  urls = DEFAULT_URLS,
}: {
  urls?: string[]
}) => {
  return (
    <DesignersDivHeader>
      {(urls ?? DEFAULT_URLS).map((url, idx) => (
        <Image className="avatar" key={idx} src={url} />
      ))}
    </DesignersDivHeader>
  )
}

const DesignersHeader = ({
  title,
  subtitle,
  urls,
}: {
  title: string
  subtitle?: string
  urls?: string[]
}) => {
  const downFromTablet = useMedia(Breakpoint.downFromTablet)
  if (downFromTablet)
    return (
      <>
        <DesignersAvatarsHeader urls={urls} />
        <div>
          <h3>{title}</h3>
          {subtitle && <p>{subtitle}</p>}
        </div>
      </>
    )

  return (
    <HeaderContainer>
      <DesignersAvatarsHeader urls={urls} />
      <div>
        <h3 className="no-margin">{title}</h3>
        {subtitle && <p className="light-gray">{subtitle}</p>}
      </div>
    </HeaderContainer>
  )
}

export default DesignersHeader

const DesignersDiv = styled.div`
  min-width: 200px;

  img {
    min-height: 48px;
    min-width: 48px;
  }
  .avatar:not(:first-child) {
    margin-left: -30px;
  }
  img.avatar {
    border-radius: 100% !important;
    margin-right: 14px !important;
    height: 60px !important;
    width: 60px !important;
    margin-bottom: 2em;
  }
`

const DesignersDivHeader = styled.div`
  min-width: 170px;

  img {
    min-height: 48px;
    min-width: 48px;
  }
  .avatar:not(:first-child) {
    margin-left: -30px;
  }
  img.avatar {
    border-radius: 100% !important;
    margin-right: 14px !important;
    height: 60px !important;
    width: 60px !important;
    @media ${Breakpoint.onlyMobile} {
      margin-bottom: 2em;
    }
  }
`

const HeaderContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: min-content auto;
  grid-template-rows: max-content max-content;

  & > img {
    grid-row: 1 / span 2;
    min-height: 80px;
    min-width: 80px;
    margin: 0 !important;
  }

  & > h1 {
    font-size: 36px;
    font-weight: 300;
    margin: 0;
  }

  & > div.actions {
    display: flex;
    column-gap: 32px;
  }

  & > p {
    color: ${Colors.gray600};
    font-weight: 300;
    margin: 0;
  }

  @media ${Breakpoint.onlyMobile} {
    & > div > p {
      margin-top: 10px !important;
    }

    & > img {
      grid-row: 1;
    }

    & > div.actions {
      column-gap: 8px;
      grid-column: 1 / span 2;
      justify-content: space-around;
      margin-top: 8px;
    }

    & > p {
      grid-column: 1 / span 2;
      text-align: center;
    }
  }
`
