import React, { useEffect, useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import moment from 'moment'
import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import DesignerHeader from 'components/app/project/designer-header'
import FormHeader from 'components/shared/header-form'
import HelpMessage from 'components/shared/help-message'
import Card from 'components/welcome/card'
import DesignersHeader from 'components/welcome/prospect/designers-header'
import LeadTimeProvider, { useLeadTime } from 'context/lead-times/provider'
import useProspect from 'context/prospect/use'
import fireEvent from 'context/tracking/events'
import { useZipCodes } from 'context/zip-codes/provider'
import Button from 'design-system/components/button'
import SecondaryCard from 'design-system/components/cards/secondary-card'
import { Drawer } from 'design-system/components/drawer/drawer'
import AppLayout from 'design-system/containers/app-layout'
import { Breakpoint, Colors } from 'styles/app/system'
import { MeetingTypeProspect } from 'types/meeting'
import { resourcesUrls } from 'views/utils'

import UpdateZipCodeModal from './update-zipcode-modal'
import AffiliateBanner from '../../affiliate-banner'

const Dashboard = () => {
  const [enableUpdateZipCodePack, setEnableUpdateZipCodePack] = useState(false)
  const {
    designer,
    hasProject,
    isEstimateFirst,
    isQuizCompleted,
    isTradeProspect,
    nearestStudio,
    meetingUpcoming,
    prospect,
    prospectUploads,
    prospectUrlComponent,
  } = useProspect()

  const { fetchUniqueZipCode, loading, currentZipCode } = useZipCodes()
  const { leadTime } = useLeadTime()

  useEffect(() => {
    if (isTradeProspect) {
      navigate(`/welcome/${prospect?.id}/trade`)
      return
    }

    if (hasProject && designer) {
      navigate(`/welcome/${prospect?.id}/thank-you`)
      return
    }

    fetchUniqueZipCode({
      variables: { where: { zipCode: prospect?.zipCode || 'miss' } },
    })
  }, [prospect, designer])

  if (!prospect || !prospect?.id) return null

  const selectionCenterMeetingId =
    meetingUpcoming?.metadata?.selectionCenter?.identifier

  const supportBanner = selectionCenterMeetingId ? (
    <p>
      You have a {selectionCenterMeetingId.toUpperCase()} Studio visit scheduled
      for {moment(meetingUpcoming.startTime).format('L')} at{' '}
      {moment(meetingUpcoming.startTime).format('LT')}{' '}
      {moment.tz(moment.tz.guess()).zoneAbbr()}{' '}
      <Button
        color="gray"
        fontAwesomeIcon="arrow-right"
        iconAnimation="right"
        kind="text"
        onClick={() =>
          navigate(
            `/welcome/${prospect.id}/${
              selectionCenterMeetingId === 'NY'
                ? 'new-york'
                : selectionCenterMeetingId === 'SF'
                ? 'san-francisco'
                : 'denver'
            }`,
          )
        }
        style={{
          minHeight: 'unset',
          margin: '6px 0 6px 32px',
          pointerEvents: 'auto',
        }}
        text={'See details'}
      />
    </p>
  ) : meetingUpcoming?.metadata?.type === MeetingTypeProspect.FREE &&
    meetingUpcoming?.duration === 15 ? (
    <p>
      You have a call with a FORM Advisor scheduled for{' '}
      {moment(meetingUpcoming.startTime).format('L')} at{' '}
      {moment(meetingUpcoming.startTime).format('LT')}{' '}
      {moment.tz(moment.tz.guess()).zoneAbbr()}{' '}
      <Button
        color="gray"
        fontAwesomeIcon="arrow-right"
        iconAnimation="right"
        kind="text"
        onClick={() => navigate(`/welcome/${prospect.id}/advisory-call`)}
        style={{
          minHeight: 'unset',
          margin: '6px 0 6px 32px',
          pointerEvents: 'auto',
        }}
        text={'See details'}
      />
    </p>
  ) : nearestStudio ? (
    <p>
      Want to experience our cabinetry in person?{' '}
      <Button
        color="gray"
        fontAwesomeIcon="arrow-right"
        iconAnimation="right"
        kind="text"
        onClick={() => navigate(`/welcome/${prospect.id}/${nearestStudio}`)}
        style={{
          minHeight: 'unset',
          margin: '6px 0 6px 32px',
          pointerEvents: 'auto',
        }}
        text={`Visit ${
          nearestStudio === 'new-york'
            ? 'NY'
            : nearestStudio === 'san-francisco'
            ? 'SF'
            : 'Denver'
        } Studio`}
      />
    </p>
  ) : (
    <p>
      Have more questions before you get started? Text us at{' '}
      <a
        style={{
          fontWeight: '300',
          color: `${Colors.gray600}`,
          textDecoration: 'underline',
        }}
        href="sms:+14154293515"
        target="_blank"
      >
        (415) 429-3515
      </a>
    </p>
  )

  const banner = currentZipCode
    ? {
        message: (
          <p>
            Orders placed today for {currentZipCode.stateName} (
            {prospect?.zipCode}), are anticipated to arrive in{' '}
            <b>
              {leadTime?.weeksLow}-{leadTime?.weeksHigh} weeks
            </b>
            .
          </p>
        ),
        button: (
          <Button
            kind="text"
            text="Learn more"
            fontAwesomeIcon="arrow-right"
            onClick={() => navigate(`/welcome/${prospect?.id}/timeline`)}
          />
        ),
      }
    : {
        message: (
          <p>
            Sorry, we don’t currently deliver to your zip code. You can receive
            our orders anywhere in the continental US and handle transportation
            from there. Email hello@formkitchens.com for more information.
          </p>
        ),
        button: (
          <Button
            kind="text"
            text="Update zip code"
            fontAwesomeIcon="arrow-right"
            onClick={() => setEnableUpdateZipCodePack(true)}
          />
        ),
      }

  const quizCard = (
    <Card
      cardType={isQuizCompleted ? 'complete' : 'toDo'}
      numberStep={isQuizCompleted ? undefined : isEstimateFirst ? '2' : '1'}
      image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/project-information.svg`}
      onClick={() =>
        navigate(`/welcome/${prospectUrlComponent}/project-information`)
      }
      title="Project information"
    />
  )

  const quizCardSecondary = (
    <SecondaryCard
      completed={isQuizCompleted}
      image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/project-information.svg`}
      onClick={() =>
        navigate(`/welcome/${prospectUrlComponent}/project-information`)
      }
      title={
        isEstimateFirst ? '2. Project information' : '1. Project information'
      }
    />
  )

  const estimateCard = (
    <Card
      cardType={prospect?.data?.estimate ? 'complete' : 'toDo'}
      numberStep={
        prospect?.data?.estimate ? undefined : isEstimateFirst ? '1' : '2'
      }
      image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/estimator.svg`}
      onClick={() =>
        prospect?.data?.estimate
          ? navigate(
              `/welcome/${prospectUrlComponent}/estimate/results?backTo=dashboard`,
            )
          : navigate(`/welcome/${prospectUrlComponent}/estimate/layout`, {
              state: { comingFromDashboard: true },
            })
      }
      title="Get your estimate"
    />
  )

  const estimateCardSecondary = (
    <SecondaryCard
      completed={!!prospect?.data?.estimate}
      image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/estimator.svg`}
      onClick={() =>
        prospect?.data?.estimate
          ? navigate(
              `/welcome/${prospectUrlComponent}/estimate/results?backTo=dashboard`,
            )
          : navigate(`/welcome/${prospectUrlComponent}/estimate/layout`, {
              state: { comingFromDashboard: true },
            })
      }
      title={isEstimateFirst ? '1. Get your estimate' : '2. Get your estimate'}
    />
  )

  return (
    <AppLayout
      header={<FormHeader />}
      notificationBar={
        <AffiliateBanner
          affiliateCode={prospect?.data?.affiliateCode ?? ''}
          styleProps={{ transform: 'translateY(60px)' }}
        />
      }
    >
      <Grid stackable>
        {/* Add notifications here */}
        <Grid.Row>
          <Grid.Column>
            <HelpMessage content={supportBanner} fit />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            {designer ? (
              <DesignerHeader
                title={`Welcome ${prospect?.firstName}, let's get started`}
                designerPicture={designer?.picture}
                subtitle={
                  'Complete the steps below to schedule our first call.'
                }
              />
            ) : (
              <DesignersHeader
                title={`Welcome ${prospect?.firstName}, let's get started`}
                subtitle="Please complete the steps below to schedule a call with one of our expert kitchen designers."
              />
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            {!loading && (
              <HelpMessage
                content={
                  <BannerDiv>
                    {banner.message}
                    {banner.button}
                  </BannerDiv>
                }
                fit
                showIcon={false}
              />
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="up-mobile" stretched styled={{ margin: '0 5px' }}>
          <Grid.Column largeScreen={4} computer={4} tablet={8}>
            {isEstimateFirst ? estimateCard : quizCard}
          </Grid.Column>
          <Grid.Column largeScreen={4} computer={4} tablet={8}>
            {isEstimateFirst ? quizCard : estimateCard}
          </Grid.Column>
          <Grid.Column largeScreen={4} computer={4} tablet={8}>
            <Card
              cardType={prospectUploads?.length ? 'complete' : 'toDo'}
              numberStep={!prospectUploads?.length ? '3' : undefined}
              image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/floor-plan.svg`}
              onClick={() =>
                navigate(`/welcome/${prospectUrlComponent}/floor-plan`)
              }
              title="Show us your space"
            />
          </Grid.Column>
          <Grid.Column largeScreen={4} computer={4} tablet={8}>
            <Card
              cardType="dark"
              buttonText="Complete"
              image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/schedule-call-black.svg`}
              onClick={() => {
                fireEvent({
                  type: 'schedule_started',
                })
                navigate(`/welcome/${prospectUrlComponent}/schedule-call`)
              }}
              title="Schedule a call"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="only-mobile" stretched>
          <Grid.Column>
            {isEstimateFirst ? estimateCardSecondary : quizCardSecondary}
          </Grid.Column>
          <Grid.Column>
            {isEstimateFirst ? quizCardSecondary : estimateCardSecondary}
          </Grid.Column>
          <Grid.Column>
            <SecondaryCard
              completed={!!prospectUploads?.length}
              image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/floor-plan.svg`}
              onClick={() =>
                navigate(`/welcome/${prospectUrlComponent}/floor-plan`)
              }
              title="3. Show us your space"
            />
          </Grid.Column>
          <Grid.Column>
            <SecondaryCard
              completed={!!hasProject}
              image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/schedule-call-black.svg`}
              onClick={() => {
                fireEvent({
                  type: 'schedule_started',
                })
                navigate(`/welcome/${prospectUrlComponent}/schedule-call`)
              }}
              title="Schedule a call"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <div style={{ margin: '30px 0' }}>
        <Drawer
          allowOutsideClick
          content={
            <StyledGrid stackable>
              <Grid.Row stretched>
                <Grid.Column computer={4} tablet={8} mobile={16}>
                  <SecondaryCard
                    icon="oven"
                    onClick={() => window.open(resourcesUrls.catalog)}
                    title="Kitchen Catalog"
                  />
                </Grid.Column>
                <Grid.Column computer={4} tablet={8} mobile={16}>
                  <SecondaryCard
                    icon="heart"
                    onClick={() => window.open(resourcesUrls.inspirationGuide)}
                    title="Inspiration Guide"
                  />
                </Grid.Column>
                <Grid.Column computer={4} tablet={8} mobile={16}>
                  <SecondaryCard
                    icon="ballot-check"
                    onClick={() => window.open(resourcesUrls.projectHandbook)}
                    title="Project Handbook"
                  />
                </Grid.Column>
                <Grid.Column computer={4} tablet={8} mobile={16}>
                  <SecondaryCard
                    icon="book-heart"
                    onClick={() => window.open(resourcesUrls.caseStudies)}
                    title="Case Studies"
                  />
                </Grid.Column>
                <Grid.Column computer={4} tablet={8} mobile={16}>
                  <SecondaryCard
                    icon="images"
                    onClick={() =>
                      window.open('https://formkitchens.com/projects')
                    }
                    title="Gallery"
                  />
                </Grid.Column>
                <Grid.Column computer={4} tablet={8} mobile={16}>
                  <SecondaryCard
                    icon="ballot-check"
                    onClick={() =>
                      window.open('https://formkitchens.com/how-it-works')
                    }
                    title="How it works"
                  />
                </Grid.Column>
                <Grid.Column computer={4} tablet={8} mobile={16}>
                  <SecondaryCard
                    icon="sparkles"
                    onClick={() => window.open(resourcesUrls.technicalGuide)}
                    title="Technical Guide"
                  />
                </Grid.Column>
                <Grid.Column computer={4} tablet={8} mobile={16}>
                  <SecondaryCard
                    icon="circle-question"
                    onClick={() => window.open('https://formkitchens.com/faq')}
                    title="FAQs"
                  />
                </Grid.Column>
              </Grid.Row>
            </StyledGrid>
          }
          title="Useful links"
        />
      </div>
      <UpdateZipCodeModal
        open={enableUpdateZipCodePack}
        onClose={() => setEnableUpdateZipCodePack(false)}
      />
    </AppLayout>
  )
}

export default (_: RouteComponentProps) => {
  return (
    <LeadTimeProvider>
      <Dashboard />
    </LeadTimeProvider>
  )
}

const BannerDiv = styled.div`
  button {
    min-width: fit-content;
  }
  @media ${Breakpoint.upFromMobile} {
    display: inline-flex;
    align-items: center;
    column-gap: 32px;
  }
`

const StyledGrid = styled(Grid)`
  &&&&&&&& {
    @media ${Breakpoint.onlyMobile} {
      margin: 10px 0;
    }
    .row {
      margin-bottom: 50px;
      row-gap: 16px;
      div.column {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
      }
    }
  }
`
