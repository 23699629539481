import React, { useEffect } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import Loader from 'components/welcome/estimate/loader'
import EstimatorStepLayout from 'components/welcome/estimate/step-layout'
import useEstimate from 'context/estimate/use'
import useProspect from 'context/prospect/use'
import SaveToast from 'design-system/components/save-toast'
import { Breakpoint } from 'styles/app/system'
import {
  ResultsContainer,
  ResultsRow,
} from 'styles/welcome/components/estimate'

import DetailsSection from './details'
import { CssOverride } from '../../utils'

const EstimateResults = ({
  location,
}: RouteComponentProps<{ prospect_id?: string }>) => {
  const { prospect } = useProspect()
  const { loadingUpgrades } = useEstimate()

  const { comingFromFlow } = (location?.state as {
    comingFromFlow?: boolean
  }) ?? { comingFromFlow: false }

  const completeProjectInfo = () => {
    navigate(`/welcome/${prospect?.id}/quiz/project-info`)
  }

  useEffect(() => {
    if (prospect && !prospect?.data.estimate) {
      navigate(`/welcome/${prospect?.id}/estimate/layout`)
    }
  }, [prospect])

  return prospect?.data.estimate ? (
    <>
      <CssOverride />
      <EstimatorStepLayout
        hideBottomNav
        step="results"
        handleNext={completeProjectInfo}
        hideBackButton
        singleColumnLayout
        mainContent={
          <ResultsContainer id="resultsContainer">
            <Grid padded stackable>
              <ResultsRow>
                <Grid.Column textAlign="center">
                  <Title>{prospect?.firstName}'s Kitchen</Title>
                </Grid.Column>
              </ResultsRow>

              <DetailsSection />
            </Grid>
            <div style={{ margin: '20px 0' }}>
              <SaveToast
                mainButtonText="Complete"
                message="Finished reviewing your estimate?"
                onClick={() => {
                  navigate(`/welcome/${prospect?.id}`)
                }}
                show={!!comingFromFlow}
              />
            </div>
            {loadingUpgrades ? <Loader /> : null}
          </ResultsContainer>
        }
      />
    </>
  ) : null
}

export default EstimateResults

const Title = styled.h2`
  margin: 50px 0 !important;

  @media ${Breakpoint.onlyMobile} {
    margin: 20px 0 0 0 !important;
  }
`
