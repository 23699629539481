import React from 'react'

import { navigate } from 'gatsby'

import PaymentModule from 'components/shared/payment-module'
import StartProjectCheckout from 'components/welcome/prospect/start-project-checkout'
import useAuth from 'context/auth/use'
import useProspect from 'context/prospect/use'
import ThankYouCard from 'design-system/components/guide/thank-you-card'
import { Section } from 'design-system/components/guide/utils'
import { PaymentType } from 'types/payment'

const DesignDeposit = () => {
  const { isFORMStaff } = useAuth()
  const { hasProject, prospect, refetch } = useProspect()

  return (
    <Section id="design-deposit">
      <PaymentModule
        hidePaymentRequest={isFORMStaff && !hasProject}
        paymentRequest={
          hasProject ? (
            <ThankYouCard
              onClick={() =>
                navigate(
                  `/login/?email=${encodeURIComponent(
                    prospect?.email.trim().toLocaleLowerCase() ?? '',
                  )}`,
                )
              }
            />
          ) : (
            <StartProjectCheckout onCompleted={() => refetch()} />
          )
        }
        paymentType={PaymentType.DESIGN_DEPOSIT}
        title="Start working with a FORM designer for $299"
      />
    </Section>
  )
}

export default DesignDeposit
