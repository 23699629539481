import React, { useEffect, useState } from 'react'

import { navigate, RouteComponentProps } from '@reach/router'
import { Divider, Grid } from 'semantic-ui-react'

import CalendlyInlineScheduler from 'components/shared/meeting/calendly-inline-scheduler'
import MeetingConfirmation from 'components/shared/meeting/confirmation'
import useProspect from 'context/prospect/use'
import AppLayout from 'design-system/containers/app-layout'
import { MeetingTypeProspect } from 'types/meeting'

const SFSelectionCenter = (_: RouteComponentProps) => {
  const {
    prospect,
    refetch: refetchProspect,
    startPolling,
    stopPolling,
    meetingUpcoming,
  } = useProspect()

  const [isPolling, setIsPolling] = useState<boolean>(false)

  const onEventScheduled = async () => {
    startPolling(2500)
    setIsPolling(true)
  }

  useEffect(() => {
    if (isPolling) {
      stopPolling()
      setIsPolling(false)
      refetchProspect()
      navigate(`/welcome/${prospect?.id}`)
    }
  }, [prospect])

  return (
    <AppLayout>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h2>SF Studio</h2>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          {meetingUpcoming ? (
            <Grid.Column
              computer={16}
              tablet={16}
              mobile={16}
              textAlign="center"
            >
              <Divider hidden />
              <MeetingConfirmation
                hideActions
                meeting={meetingUpcoming}
                prospect={prospect}
                onUpdateEvent={onEventScheduled}
                style={{ margin: '0 auto' }}
              />
            </Grid.Column>
          ) : (
            <Grid.Column>
              <CalendlyInlineScheduler
                calendlyUrl={process.env.GATSBY_CALENDLY_HOMEPAGE_URL_SF ?? ''}
                user={prospect}
                prospectEmail={prospect?.email || ''}
                type={MeetingTypeProspect.SELECTION_CENTER}
              />
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    </AppLayout>
  )
}

export default SFSelectionCenter
