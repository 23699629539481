import React, { useContext, useMemo } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import queryString from 'query-string'
import { Grid, Segment } from 'semantic-ui-react'
import styled from 'styled-components'

import HelpMessage from 'components/shared/help-message'
import Loader from 'components/shared/loader'
import NotificationBar from 'components/shared/notification-bar'
import Header from 'components/welcome/header'
import { DesignersAvatars } from 'components/welcome/prospect/designers-header'
import { AffiliateCodes } from 'context/project/utils-discounts'
import fireEvent from 'context/tracking/events'
import { TrackingContext } from 'context/tracking/provider'
import useUserUniqueWithFiles from 'context/user/use-unique-files'
import { WebsiteButton as Button } from 'mx/components/section-components'
import { Breakpoint, Colors } from 'styles/app/system'

import { parseQueryString } from './project-info/utils'

const StartConsultation = (props: RouteComponentProps) => {
  const { state: utmState } = useContext(TrackingContext)
  const parsed = parseQueryString(props.location?.search || '')
  const affiliateCode = parsed.affiliateCode || utmState.afmc
  const designerId = parsed.designerId
  const designerEmailPrefix = parsed.designer

  const affiliateData = useMemo(() => {
    return AffiliateCodes.find((ac) => ac.active && ac.code === affiliateCode)
  }, [affiliateCode])

  const { user: designer, loading } = useUserUniqueWithFiles(
    designerId,
    designerEmailPrefix && `${designerEmailPrefix}@formkitchens.com`,
  )

  if ((designerId || designerEmailPrefix) && loading) {
    return <Loader />
  }

  return (
    <>
      <Header onExit={async () => navigate(`/`)} />
      <StartQuizPage basic>
        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column
              className="content"
              largeScreen={6}
              computer={7}
              tablet={8}
              mobile={16}
              verticalAlign="middle"
            >
              {affiliateData && (
                <DiscountBanner>
                  <NotificationBar
                    isLight
                    isDiscount
                    content={
                      <div className="discount-content">
                        <h6>{affiliateData.code}</h6>
                        <p>
                          {affiliateData.amount} {affiliateData.description}*
                        </p>
                      </div>
                    }
                  />
                  <p>*Offer cannot be combined with any other discount.</p>
                </DiscountBanner>
              )}
              <DesignersAvatars
                urls={designer ? [designer.picture] : undefined}
              />
              <h2 style={{ marginTop: '10px' }}>
                {designer
                  ? `Hi, it's ${designer.firstName}, your designer. Great to be introduced.`
                  : 'Meet your designer to get an estimate.'}
              </h2>
              <p style={{ color: Colors.gray600, margin: '20px 0' }}>
                Before we schedule a call, tell us a little bit more about your
                project.
              </p>
              <Button
                rounded
                color="transparentBlack"
                onClick={() => {
                  fireEvent({
                    type: 'quiz_started',
                  })
                  props.navigate?.(
                    `/welcome/new/quiz/project-info${
                      affiliateCode || designerId || designerEmailPrefix
                        ? `?${queryString.stringify({
                            ...(affiliateCode && {
                              affiliateCode,
                            }),
                            ...(designerId && {
                              designerId,
                            }),
                            ...(designerEmailPrefix && {
                              designer: designerEmailPrefix,
                            }),
                          })}`
                        : ''
                    }`,
                  )
                }}
                style={{ marginBottom: 48 }}
              >
                Get Started
              </Button>
              {!designer && (
                <HelpMessage
                  content={
                    <HelpMessageContent>
                      <p className="gray">
                        Want to experience our cabinetry in person? Book an
                        appointment at our{' '}
                        <a
                          style={{
                            fontWeight: '300',
                            color: `${Colors.gray600}`,
                            textDecoration: 'underline',
                          }}
                          href="https://formkitchens.com/new-york/"
                          target="_blank"
                        >
                          NYC
                        </a>
                        ,{' '}
                        <a
                          style={{
                            fontWeight: '300',
                            color: `${Colors.gray600}`,
                            textDecoration: 'underline',
                          }}
                          href="https://formkitchens.com/denver/"
                          target="_blank"
                        >
                          Denver
                        </a>
                        , or{' '}
                        <a
                          style={{
                            fontWeight: '300',
                            color: `${Colors.gray600}`,
                            textDecoration: 'underline',
                          }}
                          href="https://formkitchens.com/san-francisco/"
                          target="_blank"
                        >
                          SF
                        </a>{' '}
                        Studio space.
                      </p>
                    </HelpMessageContent>
                  }
                />
              )}
            </Grid.Column>
            <Grid.Column floated="right" computer={9} tablet={8} mobile={16}>
              <BackgroundImage
                src={`${process.env.GATSBY_CDN_ROOT}/images/onboarding/onboarding-2023.webp`}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </StartQuizPage>
    </>
  )
}

export default StartConsultation

const StartQuizPage = styled(Segment)`
  background-color: ${Colors.white} !important;

  a {
    color: #e98d6f;
    text-decoration: underline solid #e98d6f;
    transition: color 200ms;
    &:hover {
      color: #e97b57;
      text-decoration: underline solid #e97b57;
    }
  }

  &&&&& {
    margin: 0;
    padding-top: 68px;
    position: absolute;
    top: 0;
    width: 100vw;

    .grid {
      height: calc(100vh - 68px);

      .content {
        padding: 0 55px 0;
      }
    }

    @media ${Breakpoint.onlyMobile} {
      .grid {
        .content {
          height: min-content;
          padding-top: 65px !important;
        }
      }
    }
  }
`

const BackgroundImage = styled.div<{ src: string }>`
  background: url('${(props) => props.src}') no-repeat center;
  background-size: cover;
  display: flex;
  height: calc(100vh - 68px);
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  @media ${Breakpoint.onlyMobile} {
    display: none;
  }
`

const DiscountBanner = styled.div`
  margin: 30px 0 50px;
  width: fit-content;
  div.discount-content {
    align-items: center;
    column-gap: 20px;
    display: inline-flex;
    h6,
    p {
      color: ${Colors.black};
      margin: 0;
    }
  }
`

const HelpMessageContent = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: auto max-content;
  gap: 8px;

  @media ${Breakpoint.onlyMobile} {
    grid-template-columns: auto;

    & > button {
      width: calc(100% + 36px);
      transform: translateX(-36px);
    }
  }
`
