import React, { useEffect } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { Grid } from 'semantic-ui-react'

import Header from 'components/app/project/header/header-basic'
import FormHeader from 'components/shared/header-form'
import CalendlyInlineScheduler from 'components/shared/meeting/calendly-inline-scheduler'
import useProspect from 'context/prospect/use'
import fireEvent from 'context/tracking/events'
import AppLayout from 'design-system/containers/app-layout'
import { MeetingTypeProject } from 'types/meeting'

const ScheduleCall = (_: RouteComponentProps) => {
  const {
    budgetAnswer,
    designCallOneOffUrl,
    designer,
    hasProject,
    prospect,
    startPolling,
    stopPolling,
    refetch,
  } = useProspect()

  const handleEventScheduled = () => {
    startPolling(2000)
  }

  useEffect(() => {
    if (hasProject && designer) {
      stopPolling()
      refetch()
      navigate(`/welcome/${prospect?.id}/thank-you`)
    }
  }, [prospect, designer])

  useEffect(() => {
    refetch()
  }, [])

  return (
    <AppLayout
      header={
        prospect?.id ? (
          <Header onBack={() => navigate?.(`/welcome/${prospect.id}`)} />
        ) : (
          <FormHeader />
        )
      }
    >
      <Grid stackable>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h3>Let's get started</h3>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column computer={11} tablet={16} textAlign="center">
            <p>
              Choose a time from our team calendar below and we'll match you
              with a great designer that fits your schedule.
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column>
            <CalendlyInlineScheduler
              calendlyUrl={
                designCallOneOffUrl
                  ? designCallOneOffUrl
                  : designer
                  ? `https://calendly.com/${designer.data.calendlyId}/${
                      designer.data.calendlyUrlSlugDesignCall || 'design-call'
                    }`
                  : (process.env.GATSBY_CALENDLY_DESIGN_CALL_URL as string)
              }
              user={prospect}
              onEventScheduled={() => {
                handleEventScheduled()
                fireEvent({
                  type: 'identify',
                  email: prospect?.email,
                  firstName: prospect?.firstName,
                  lastName: prospect?.lastName,
                  phone: prospect?.phone ?? '',
                  state: prospect?.data.state ?? '',
                  zipCode: prospect?.zipCode ?? '',
                })
                fireEvent({
                  type: 'schedule_completed',
                  budget: budgetAnswer?.value,
                  email: prospect?.email,
                  firstName: prospect?.firstName,
                  lastName: prospect?.lastName,
                  meetingType: 'DESIGN',
                  phone: prospect?.phone,
                  state: prospect?.data.state,
                  zipCode: prospect?.zipCode,
                })
              }}
              prospectEmail={prospect?.email || ''}
              type={MeetingTypeProject.DESIGN}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AppLayout>
  )
}

export default ScheduleCall
