import React, { useMemo } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import { AffiliateCodes } from 'context/project/utils-discounts'
import { Breakpoint, Colors } from 'styles/app/system'

const AffiliateBanner = ({
  affiliateCode,
  styleProps,
}: {
  affiliateCode: string
  styleProps?: React.CSSProperties
}) => {
  const affiliateData = useMemo(() => {
    return AffiliateCodes.find((ac) => ac.code === affiliateCode)
  }, [affiliateCode])

  if (!affiliateData) return null
  return (
    <DiscountBanner style={styleProps}>
      <p>
        <FontAwesomeIcon
          icon={['fal', 'ticket']}
          style={{ marginRight: '16px' }}
        />
        <span style={{ fontWeight: '300' }}>
          {affiliateData.amount} {affiliateData.description}
        </span>{' '}
        applied to your project. This offer cannot be combined with any other
        discount.
      </p>
    </DiscountBanner>
  )
}

export default AffiliateBanner

const DiscountBanner = styled.div`
  align-items: center;
  border: 1px solid ${Colors.gray500};
  display: flex;
  justify-content: center;
  transform: translate(-40px, -50px);
  width: 100vw;
  min-height: 40px;

  p {
    color: ${Colors.gray600};
    text-align: center;
    @media ${Breakpoint.onlyMobile} {
      font-size: 14px;
    }
  }

  @media ${Breakpoint.onlyMobile} {
    transform: translate(-15px, -40px);
    padding: 0 10px;
  }
`
