import React, { useEffect } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import DesignerHeader from 'components/app/project/designer-header'
import FormHeader from 'components/shared/header-form'
import HelpMessage from 'components/shared/help-message'
import Card from 'components/welcome/card'
import DesignersHeader from 'components/welcome/prospect/designers-header'
import LeadTimeProvider, { useLeadTime } from 'context/lead-times/provider'
import useProspect from 'context/prospect/use'
import fireEvent from 'context/tracking/events'
import { useZipCodes } from 'context/zip-codes/provider'
import Button from 'design-system/components/button'
import SecondaryCard from 'design-system/components/cards/secondary-card'
import { Drawer } from 'design-system/components/drawer/drawer'
import AppLayout from 'design-system/containers/app-layout'
import { Breakpoint } from 'styles/app/system'
import { resourcesUrls } from 'views/utils'

import AffiliateBanner from '../../affiliate-banner'

const Dashboard = () => {
  const {
    hasProject,
    isContactCompleted,
    isCompanyCompleted,
    prospect,
    prospectUrlComponent,
    tradeDesigner,
  } = useProspect()
  const { fetchUniqueZipCode, currentZipCode } = useZipCodes()
  const { leadTime } = useLeadTime()

  useEffect(() => {
    if (!prospect) return

    if (hasProject && tradeDesigner) {
      navigate(`/welcome/${prospect.id}/trade/thank-you`)
      return
    }
    fetchUniqueZipCode({
      variables: { where: { zipCode: prospect.zipCode || 'miss' } },
    })
  }, [prospect, tradeDesigner])

  if (!prospect || !prospect.id) return null

  let stepsCompleted = 0
  if (isContactCompleted) stepsCompleted += 1
  if (isCompanyCompleted) stepsCompleted += 1

  const readyToSchedule = isContactCompleted && isCompanyCompleted

  const banner = currentZipCode && {
    message: (
      <p>
        Orders placed today for {currentZipCode.stateName} ({prospect?.zipCode}
        ), are anticipated to arrive in{' '}
        <b>
          {leadTime?.weeksLow}-{leadTime?.weeksHigh} weeks
        </b>
        .
      </p>
    ),
    button: (
      <Button
        kind="text"
        text="Learn more"
        fontAwesomeIcon="arrow-right"
        onClick={() => navigate(`/welcome/${prospect.id}/timeline`)}
      />
    ),
  }

  return (
    <AppLayout
      header={<FormHeader />}
      notificationBar={
        <AffiliateBanner
          affiliateCode={prospect.data?.affiliateCode ?? ''}
          styleProps={{ transform: 'translateY(60px)' }}
        />
      }
    >
      <Grid stackable>
        <Grid.Row>
          {tradeDesigner ? (
            <DesignerHeader
              title={`Welcome ${prospect.firstName}, let's get started`}
              designerPicture={tradeDesigner?.picture}
              subtitle={
                'Enter your company information below to schedule our first call.'
              }
            />
          ) : (
            <DesignersHeader
              title={`Welcome ${prospect.firstName}, let's get started`}
              subtitle="Schedule a call below with one of our expert trade designers."
            />
          )}
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            {banner && (
              <HelpMessage
                content={
                  <BannerDiv>
                    {banner.message}
                    {banner.button}
                  </BannerDiv>
                }
                fit
                showIcon={false}
              />
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="up-mobile" stretched styled={{ margin: '0 5px' }}>
          <Grid.Column largeScreen={4} computer={4} tablet={8}>
            <Card
              cardType={isContactCompleted ? 'complete' : 'toDo'}
              numberStep={!isContactCompleted ? '1' : undefined}
              image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/contact.svg`}
              onClick={() =>
                navigate(
                  `/welcome/${prospectUrlComponent}/trade/contact-information`,
                )
              }
              title="Contact Information"
            />
          </Grid.Column>
          <Grid.Column largeScreen={4} computer={4} tablet={8}>
            <Card
              cardType={isCompanyCompleted ? 'complete' : 'toDo'}
              numberStep={!isCompanyCompleted ? '2' : undefined}
              image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/company.svg`}
              onClick={() =>
                navigate(
                  `/welcome/${prospectUrlComponent}/trade/company-information`,
                )
              }
              title="Company Information"
            />
          </Grid.Column>
          <Grid.Column largeScreen={4} computer={4} tablet={8}>
            <Card
              cardType="dark"
              disabled={!readyToSchedule}
              buttonText={
                readyToSchedule
                  ? 'Book'
                  : `Awaiting ${2 - stepsCompleted} ${
                      stepsCompleted === 1 ? 'Step' : 'Steps'
                    }`
              }
              image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/schedule-call.svg`}
              onClick={() => {
                fireEvent({
                  type: 'schedule_started',
                })
                navigate(`/welcome/${prospectUrlComponent}/trade/schedule-call`)
              }}
              title="Schedule a Call"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="only-mobile" stretched>
          <Grid.Column>
            <SecondaryCard
              completed={isContactCompleted}
              image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/project-information.svg`}
              onClick={() =>
                navigate(
                  `/welcome/${prospectUrlComponent}/trade/contact-information`,
                )
              }
              title="1. Contact information"
            />
          </Grid.Column>
          <Grid.Column>
            <SecondaryCard
              completed={!!isCompanyCompleted}
              image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/estimator.svg`}
              onClick={() =>
                navigate(
                  `/welcome/${prospectUrlComponent}/trade/company-information`,
                )
              }
              title="2. Company information"
            />
          </Grid.Column>
          <Grid.Column>
            <SecondaryCard
              completed={!!hasProject}
              disabled={!readyToSchedule}
              image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/schedule-call-black.svg`}
              onClick={() => {
                fireEvent({
                  type: 'schedule_started',
                })
                navigate(`/welcome/${prospectUrlComponent}/trade/schedule-call`)
              }}
              title="Schedule a call"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <div style={{ margin: '30px 0' }}>
        <Drawer
          allowOutsideClick
          content={
            <StyledGrid stackable>
              <Grid.Row stretched>
                <Grid.Column computer={4} tablet={8} mobile={16}>
                  <SecondaryCard
                    icon="book-heart"
                    onClick={() => window.open(resourcesUrls.tradeDeck)}
                    title="Trade Deck"
                  />
                </Grid.Column>
                <Grid.Column computer={4} tablet={8} mobile={16}>
                  <SecondaryCard
                    icon="book-heart"
                    onClick={() => window.open(resourcesUrls.frontsAndHandles)}
                    title="Fronts and Handles"
                  />
                </Grid.Column>
                <Grid.Column computer={4} tablet={8} mobile={16}>
                  <SecondaryCard
                    icon="book-heart"
                    onClick={() => window.open(resourcesUrls.sizeGuide)}
                    title="Size Guide"
                  />
                </Grid.Column>
                <Grid.Column computer={4} tablet={8} mobile={16}>
                  <SecondaryCard
                    icon="book-heart"
                    onClick={() => window.open(resourcesUrls.catalog)}
                    title="Kitchen Overview"
                  />
                </Grid.Column>
                <Grid.Column computer={4} tablet={8} mobile={16}>
                  <SecondaryCard
                    icon="book-heart"
                    onClick={() => window.open(resourcesUrls.upgradePack)}
                    title="FORM Upgrade Pack"
                  />
                </Grid.Column>
                <Grid.Column computer={4} tablet={8} mobile={16}>
                  <SecondaryCard
                    icon="book-heart"
                    onClick={() => window.open(resourcesUrls.technicalGuide)}
                    title="FORM Technical Guide"
                  />
                </Grid.Column>
              </Grid.Row>
            </StyledGrid>
          }
          title="Useful links"
        />
      </div>
    </AppLayout>
  )
}

export default (_: RouteComponentProps) => {
  return (
    <LeadTimeProvider>
      <Dashboard />
    </LeadTimeProvider>
  )
}

const BannerDiv = styled.div`
  button {
    min-width: fit-content;
  }
  @media ${Breakpoint.upFromMobile} {
    display: inline-flex;
    align-items: center;
    column-gap: 32px;
  }
`

const StyledGrid = styled(Grid)`
  &&&&&&&& {
    @media ${Breakpoint.onlyMobile} {
      margin: 10px 0;
    }
    .row {
      margin-bottom: 50px;
      row-gap: 16px;
      div.column {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
      }
    }
  }
`
