import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'

import { StepperProps } from 'design-system/components/stepper'

import YourDetailsView from './views/details'
import YourProjectView from './views/motivation'
import YourPrioritiesView from './views/priority'
import YourProgressView from './views/progress'
import YourStyleView from './views/style'

export type ProspectFlowRouteProps = RouteComponentProps<{
  '*': string
}>

export type StepViewProps = ProspectFlowRouteProps & {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  setNextDisabled: React.Dispatch<React.SetStateAction<boolean>>
  setOnNext: React.Dispatch<
    React.SetStateAction<(() => Promise<void>) | undefined>
  >
}

type NavigationStep = StepperProps['steps'][number] & {
  subroute: string
  View: React.ComponentType<StepViewProps>
}
type StartProspectStepsType =
  | 'yourStyle'
  | 'yourProject'
  | 'yourProgress'
  | 'yourPriorities'
  | 'yourDetails'

export const PROSPECT_QUIZ_STEPS: Record<
  StartProspectStepsType,
  NavigationStep
> = {
  yourStyle: {
    label: 'Your style',
    completedElement: <FontAwesomeIcon icon={['fal', 'check']} />,
    subroute: 'your-style',
    View: YourStyleView,
  },
  yourProject: {
    label: 'Your project',
    completedElement: <FontAwesomeIcon icon={['fal', 'check']} />,
    subroute: 'your-project',
    View: YourProjectView,
  },
  yourProgress: {
    label: 'Your progress',
    completedElement: <FontAwesomeIcon icon={['fal', 'check']} />,
    subroute: 'your-progress',
    View: YourProgressView,
  },
  yourPriorities: {
    label: 'Your priorities',
    completedElement: <FontAwesomeIcon icon={['fal', 'check']} />,
    subroute: 'your-priorities',
    View: YourPrioritiesView,
  },
  yourDetails: {
    label: 'Your details',
    completedElement: <FontAwesomeIcon icon={['fal', 'check']} />,
    subroute: 'your-details',
    View: YourDetailsView,
  },
}

export const PROSPECT_QUIZ_STEPS_ARRAY = [
  PROSPECT_QUIZ_STEPS.yourStyle,
  PROSPECT_QUIZ_STEPS.yourProject,
  PROSPECT_QUIZ_STEPS.yourProgress,
  PROSPECT_QUIZ_STEPS.yourPriorities,
]

export const NO_PROSPECT_QUIZ_STEPS_ARRAY = [
  PROSPECT_QUIZ_STEPS.yourStyle,
  PROSPECT_QUIZ_STEPS.yourProject,
  PROSPECT_QUIZ_STEPS.yourProgress,
  PROSPECT_QUIZ_STEPS.yourPriorities,
  PROSPECT_QUIZ_STEPS.yourDetails,
]
