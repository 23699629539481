import React, { useEffect } from 'react'

import { navigate, RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import StepTitle from 'components/app/step-title'
import useProspect from 'context/prospect/use'
import SelectImageCard from 'design-system/components/cards/selection/select-image-card'
import { Drawer } from 'design-system/components/drawer'
import AppLayout from 'design-system/containers/app-layout'
import { Breakpoint, Colors } from 'styles/app/system'
import { responsiveText } from 'views/utils'

import ProfileCard from './profile-card'

const ProjectDescription = (_: RouteComponentProps) => {
  const {
    inspirationMoodboards,
    isQuizCompleted,
    motivationAnswer,
    priorityAnswer,
    progressAnswer,
    prospect,
    prospectUrlComponent,
  } = useProspect()

  useEffect(() => {
    if (!isQuizCompleted)
      navigate(`/welcome/${prospectUrlComponent}/quiz/project-info/your-style`)
  }, [])

  return (
    <AppLayout>
      <StepTitle title="Project information" />
      <StyledDiv>
        <Drawer
          allowOutsideClick
          content={
            <Grid stackable>
              <Grid.Row className="row-gap">
                <Grid.Column
                  largeScreen={4}
                  computer={5}
                  tablet={8}
                  mobile={16}
                >
                  <ProfileCard
                    icon="user"
                    title="First name"
                    description={responsiveText(prospect?.firstName || '', 22)}
                  />
                </Grid.Column>
                <Grid.Column
                  largeScreen={4}
                  computer={5}
                  tablet={8}
                  mobile={16}
                >
                  <ProfileCard
                    icon="user"
                    title="Last name"
                    description={responsiveText(prospect?.lastName || '', 22)}
                  />
                </Grid.Column>
                <Grid.Column
                  largeScreen={4}
                  computer={5}
                  tablet={8}
                  mobile={16}
                >
                  <ProfileCard
                    icon="envelope"
                    title="Email"
                    description={responsiveText(prospect?.email ?? '', 22)}
                  />
                </Grid.Column>
                <Grid.Column
                  largeScreen={4}
                  computer={5}
                  tablet={8}
                  mobile={16}
                >
                  <ProfileCard
                    icon="map-pin"
                    title="ZIP code"
                    description={prospect?.zipCode ?? ''}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          }
          defaultOpen
          title="Profile info"
        />
        <Drawer
          allowOutsideClick
          content={
            <Grid stackable>
              <Grid.Row className="row-gap">
                {inspirationMoodboards.map((im) => {
                  return (
                    <Grid.Column
                      key={im.id}
                      computer={4}
                      tablet={8}
                      mobile={16}
                    >
                      <SelectImageCard
                        image={`${process.env.GATSBY_CDN_ROOT}/${im.data?.imageUrl}`}
                        noHover
                      />
                    </Grid.Column>
                  )
                })}
              </Grid.Row>
            </Grid>
          }
          defaultOpen
          title="Inspiration styles"
        />
        <Drawer
          allowOutsideClick
          content={
            <Grid stackable>
              <Grid.Row className="row-gap">
                {priorityAnswer?.map((p) => {
                  return (
                    <Grid.Column
                      key={p.value}
                      computer={4}
                      tablet={8}
                      mobile={16}
                    >
                      <SelectImageCard
                        image={`${process.env.GATSBY_CDN_ROOT}/images/onboarding/priority/${p.image}`}
                        noHover
                        text={p.value}
                      />
                    </Grid.Column>
                  )
                })}
              </Grid.Row>
            </Grid>
          }
          defaultOpen
          title="Priorities"
        />
        <Drawer
          allowOutsideClick
          content={
            <Grid stackable>
              <Grid.Row className="row-gap">
                {motivationAnswer?.map((m) => {
                  return (
                    <Grid.Column
                      key={m.value}
                      computer={5}
                      tablet={8}
                      mobile={16}
                    >
                      <MotivationDiv>
                        <p className="no-margin">{m.value}</p>
                      </MotivationDiv>
                    </Grid.Column>
                  )
                })}
              </Grid.Row>
            </Grid>
          }
          defaultOpen
          title="Project motivation"
        />
        <Drawer
          allowOutsideClick
          content={
            <Grid stackable>
              <Grid.Row className="row-gap">
                {progressAnswer?.map((p) => {
                  return (
                    <Grid.Column
                      key={p.value}
                      computer={5}
                      tablet={8}
                      mobile={16}
                    >
                      <MotivationDiv>
                        <p className="no-margin">{p.value}</p>
                      </MotivationDiv>
                    </Grid.Column>
                  )
                })}
              </Grid.Row>
            </Grid>
          }
          defaultOpen
          title="Current progress"
        />
      </StyledDiv>
    </AppLayout>
  )
}

export default ProjectDescription

const MotivationDiv = styled.div`
  align-items: center;
  background: white;
  border-radius: 4px;
  border: 1px solid ${Colors.gray200};
  display: flex;
  justify-content: center;
  min-height: 60px;
  padding: 8px 16px;
  position: relative;
`

const StyledDiv = styled.div`
  display: grid;
  margin-top: 20px;
  @media ${Breakpoint.upFromMobile} {
    row-gap: 50px !important;
  }
  @media ${Breakpoint.onlyMobile} {
    row-gap: 16px !important;
  }
`
