import React, { useState } from 'react'

import { RouteComponentProps } from '@reach/router'

import PaymentModule from 'components/shared/payment-module'
import Header from 'components/welcome/header'
import StartProjectCheckout from 'components/welcome/prospect/start-project-checkout'
import { PaymentType } from 'types/payment'
import { MainContainer } from 'views/utils'

import ThankYouPage from './thank-you'

const StartProject = (_: RouteComponentProps) => {
  const [completedEmail, setCompletedEmail] = useState<string>()
  if (completedEmail) return <ThankYouPage email={completedEmail} />

  return (
    <>
      <Header />
      <MainContainer>
        <PaymentModule
          paymentRequest={
            <StartProjectCheckout
              onCompleted={(email: string) => setCompletedEmail(email)}
            />
          }
          paymentType={PaymentType.DESIGN_DEPOSIT}
          title="Start working with a FORM designer for $299"
        />
      </MainContainer>
    </>
  )
}

export default StartProject
