import React, { useContext, useEffect } from 'react'

import { Container, Grid } from 'semantic-ui-react'

import fireEvent from 'context/tracking/events'
import SelectImageCard from 'design-system/components/cards/selection/select-image-card'

import { QuizContext } from '../../index'
import { ImageGridColumn } from '../../utils'
import { StepViewProps } from '../steps'

const YourStyleView = ({ setNextDisabled, setOnNext }: StepViewProps) => {
  const { allInspirationMoodboards, dispatch, state } = useContext(QuizContext)

  const onChange = (identifier: string) => {
    dispatch({
      type: 'toggle_inspiration_moodboard',
      identifier: identifier,
    })
  }

  useEffect(() => {
    document.body.scrollTo({ top: 0 })
    window.scrollTo(0, 0)
    const onNext = async () => {
      fireEvent({
        type: 'quiz_step_completed',
        stepName: 'STYLES',
      })
    }
    setOnNext(() => onNext)
  }, [])

  useEffect(() => {
    setNextDisabled(!state.inspirationMoodboards.length)
  }, [state])

  return (
    <>
      <Container>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column textAlign="center">
              <h2>Which styles are you drawn to?</h2>
              <p className="light-gray">Click on the styles you like.</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={4} centered>
            {allInspirationMoodboards.map((s, idx) => {
              return (
                <ImageGridColumn key={idx} computer={4} mobile={16} tablet={5}>
                  <SelectImageCard
                    image={`${process.env.GATSBY_CDN_ROOT}/${s.data?.imageUrl}`}
                    onClick={() => onChange(s.identifier ?? '')}
                    selected={
                      !!state.inspirationMoodboards.find(
                        (c) => c === s.identifier,
                      )
                    }
                  />
                </ImageGridColumn>
              )
            })}
          </Grid.Row>
        </Grid>
      </Container>
    </>
  )
}

export default YourStyleView
