import React, { useContext, useEffect } from 'react'

import { Container, Grid } from 'semantic-ui-react'

import fireEvent from 'context/tracking/events'
import SelectTextCard from 'design-system/components/cards/selection/select-text-card'
import { QuizAnswerCategory } from 'types/prospect'

import { QuizContext } from '../../index'
import { OptionGridColumn } from '../../utils'
import { StepViewProps } from '../steps'

const YourProgressView = ({ setNextDisabled, setOnNext }: StepViewProps) => {
  const { dispatch, state, progressAnswer } = useContext(QuizContext)

  const onChange = (value: string) => {
    dispatch({
      type: 'toggle_answer',
      answer: { category: QuizAnswerCategory.PROGRESS, value },
    })
  }

  useEffect(() => {
    document.body.scrollTo({ top: 0 })
    window.scrollTo(0, 0)
    const onNext = async () => {
      fireEvent({
        type: 'quiz_step_completed',
        stepName: 'PROGRESS',
      })
    }
    setOnNext(() => onNext)
  }, [])

  useEffect(() => {
    setNextDisabled(!progressAnswer?.length)
  }, [state])

  return (
    <>
      <Container>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column textAlign="center">
              <h2>What have you done so far?</h2>
              <p className="light-gray">Select all that apply.</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <OptionGridColumn computer={7} tablet={10} mobile={16}>
              {progressOptions.map((o, idx) => (
                <SelectTextCard
                  key={idx}
                  onClick={() => onChange(o.value)}
                  selected={!!progressAnswer?.find((a) => a.value === o.value)}
                  text={o.text}
                />
              ))}
            </OptionGridColumn>
          </Grid.Row>
        </Grid>
      </Container>
    </>
  )
}

export default YourProgressView

const progressOptions = [
  {
    text: 'Checked inspiration online',
    selected: false,
    value: 'Checked inspiration online',
  },
  {
    text: 'Visited showrooms',
    selected: false,
    value: 'Visited showrooms',
  },
  {
    text: 'I have a floor plan',
    selected: false,
    value: 'I have a floor plan',
  },
  {
    text: 'I have multiple quotes already',
    selected: false,
    value: 'I have multiple quotes already',
  },
  {
    text: 'I’m working with an architect / designer',
    selected: false,
    value: 'I’m working with an architect / designer',
  },
  {
    text: 'I’ve engaged a contractor',
    selected: false,
    value: 'I’ve engaged a contractor',
  },
]
