import React, { useCallback, useMemo } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { useMedia } from 'react-use'
import { Grid, Image } from 'semantic-ui-react'
import styled from 'styled-components'

import FormVideo from 'components/app/form-video'
import FormHeader from 'components/shared/header-form'
import HelpMessage from 'components/shared/help-message'
import useProspect from 'context/prospect/use'
import useUserUniqueWithFiles from 'context/user/use-unique-files'
import Button from 'design-system/components/button'
import Carousel from 'design-system/components/carousel'
import AppLayout from 'design-system/containers/app-layout'
import { Breakpoint, Colors } from 'styles/app/system'
import { ProjectFile } from 'types/project-file'

const DesignerThankYouPage = (_: RouteComponentProps) => {
  const {
    prospect,
    designer: proposedDesigner,
    tradeDesigner,
    isTradeProspect,
  } = useProspect()

  const designer = useMemo(() => {
    if (isTradeProspect) return tradeDesigner
    return proposedDesigner
  }, [prospect])

  const { testimonials } = useUserUniqueWithFiles(designer?.id ?? '')

  const isMobile = useMedia(Breakpoint.onlyMobile)

  const loginButton = useMemo(() => {
    return (
      <Button
        color="dark"
        fontAwesomeIcon="arrow-right"
        kind="solid"
        size={isMobile ? 'fluid' : 'small'}
        text="Log In & Get Started"
        onClick={() =>
          navigate(
            `/login-user/?email=${encodeURIComponent(String(prospect?.email))}`,
          )
        }
      />
    )
  }, [isMobile, prospect])

  const getTestimonialSlides = useCallback(
    (files: ProjectFile[]) => {
      const width = isMobile ? '100%' : '60%'
      const height = isMobile ? '200px' : '60vh'
      const maxHeight = isMobile ? '200px' : undefined
      const objectFit = isMobile ? 'cover' : 'contain'

      return files.map((f) => ({
        id: f.id,
        item: (
          <TestimonialDiv>
            <Image
              src={`${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                f.key,
              )}`}
              style={{ height, maxHeight, width, objectFit }}
            />
            <div>
              {isMobile ? (
                <>
                  <p>
                    <b>
                      {'“'}
                      {f.metadata?.customerQuote}
                      {'”'}
                    </b>
                  </p>
                  <p className="small">{f.metadata?.customerName}</p>
                </>
              ) : (
                <>
                  <h6>
                    {'“'}
                    {f.metadata?.customerQuote}
                    {'”'}
                  </h6>
                  <p>{f.metadata?.customerName}</p>
                </>
              )}
            </div>
          </TestimonialDiv>
        ),
      }))
    },
    [isMobile],
  )

  const callAdvices: { title: string; description: string; image: string }[] =
    useMemo(() => {
      return [
        isTradeProspect
          ? {
              title: 'Share Information in Advance',
              description:
                'Upload any drawings, renders, photos or inspiration for your project. We will review these and discuss an initial estimate on the call.',
              image: '/images/app/share-info.webp',
            }
          : {
              title: 'Share Information in Advance',
              description:
                'Use your dashboard to share any inspiration or room photos. The more, the better.',
              image: '/images/app/share-info.webp',
            },
        isTradeProspect
          ? {
              title: 'Get to know our product',
              description:
                'Explore the possibilities with our catalogs, technical guides, and inspiration guide, all found on the trade dashboard.',
              image: '/images/app/know-our-product.webp',
            }
          : {
              title: 'Bring All Decision-Makers',
              description:
                "If others are part of the decision process, it's best to have them present from the start.",
              image: '/images/app/decision-makers.webp',
            },
        {
          title: 'Use a Larger Screen',
          description:
            "We'll be sharing visuals, so a laptop or tablet will enhance your experience.",
          image: '/images/app/use-larger-screen.webp',
        },
      ]
    }, [prospect])

  return (
    <>
      <AppLayout header={<FormHeader rightItem={loginButton} />}>
        <HelpMessage
          message={`${
            isMobile ? "<p class='small'>" : ''
          } We've emailed your FORM login details to <b>${prospect?.email}</b>. Not in the inbox? Check your Spam.${
            isMobile ? '</p>' : ''
          }`}
        />
        <Grid textAlign="center" className="up-mobile">
          <Grid.Row>
            <Grid.Column>
              <h2 style={{ marginTop: '56px' }}>
                Thanks for scheduling, I’m {designer?.firstName} your designer.
              </h2>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={10}>
              <h6>{designer?.data.shortBio}</h6>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            {designer?.data.introVideoUrl ? (
              <Grid.Column width={10}>
                <FormVideo
                  src={designer?.data.introVideoUrl}
                  aspectRatio="3:2"
                />
                <p
                  style={{
                    textAlign: 'left',
                  }}
                >
                  {designer?.firstName} {designer?.lastName}, Senior Kitchen
                  Designer at FORM Kitchens
                </p>
              </Grid.Column>
            ) : (
              <Grid.Column>
                <Image
                  className="avatar"
                  circular
                  style={{
                    height: '150px',
                    marginBottom: '24px',
                    width: '150px',
                  }}
                  src={designer?.picture}
                />
                <p
                  style={{
                    textAlign: 'center',
                  }}
                >
                  {designer?.firstName} {designer?.lastName}, Senior Kitchen
                  Designer at FORM Kitchens
                </p>
              </Grid.Column>
            )}
          </Grid.Row>
          {testimonials.length > 0 && (
            <>
              <Grid.Row>
                <Grid.Column>
                  <h2 style={{ margin: '120px 0 20px' }}>
                    Recent Projects and Testimonials
                  </h2>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Carousel slides={getTestimonialSlides(testimonials)} />
                </Grid.Column>
              </Grid.Row>
            </>
          )}
          <Grid.Row>
            <Grid.Column>
              <h2 style={{ margin: '120px 0 40px' }}>
                Getting the Most Out of Your Call
              </h2>
            </Grid.Column>
          </Grid.Row>
          {callAdvices.map((a) => (
            <AdviceRow key={a.image + a.title}>
              <Grid.Column width={7} textAlign="left" verticalAlign="middle">
                <h3>{a.title}</h3>
                <h6 className="gray">{a.description}</h6>
              </Grid.Column>
              <Grid.Column width={8} floated="right">
                <Image
                  floated="right"
                  src={`${process.env.GATSBY_CDN_ROOT}${a.image}`}
                />
              </Grid.Column>
            </AdviceRow>
          ))}
        </Grid>
        <Grid textAlign="center" className="only-mobile">
          <Grid.Row>
            <Grid.Column>
              <h3 style={{ margin: '0 0 24px' }}>
                Thanks for scheduling the call, I’m {designer?.firstName} your
                designer.
              </h3>
              <p style={{ marginBottom: '12px' }}>{designer?.data?.shortBio}</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              {designer?.data.introVideoUrl ? (
                <FormVideo src={designer?.data.introVideoUrl} />
              ) : (
                <Image
                  className="avatar"
                  circular
                  style={{
                    height: '116px',
                    marginBottom: '18px',
                    width: '116px',
                  }}
                  src={designer?.picture}
                />
              )}
              <p className="x-small">
                {designer?.firstName} {designer?.lastName}, Senior Kitchen
                Designer at FORM Kitchens
              </p>
            </Grid.Column>
          </Grid.Row>
          {testimonials.length > 0 && (
            <>
              <Grid.Row>
                <Grid.Column>
                  <h3 style={{ margin: '80px 0 40px' }}>
                    Recent Projects and Testimonials
                  </h3>
                </Grid.Column>
              </Grid.Row>
              <div style={{ width: '100vw' }}>
                <Carousel
                  hideNavButtons
                  slides={getTestimonialSlides(testimonials)}
                  slideSize={80}
                />
              </div>
            </>
          )}
          <Grid.Row>
            <Grid.Column>
              <h3 style={{ margin: '80px 0 20px' }}>
                Getting the Most Out of Your Call
              </h3>
            </Grid.Column>
          </Grid.Row>
          {callAdvices.map((a) => (
            <React.Fragment key={a.image + a.title}>
              <Grid.Row>
                <Grid.Column>
                  <Image
                    floated="right"
                    src={`${process.env.GATSBY_CDN_ROOT}${a.image}`}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column textAlign="left">
                  <h4>{a.title}</h4>
                  <p className="gray" style={{ marginBottom: '48px' }}>
                    {a.description}
                  </p>
                </Grid.Column>
              </Grid.Row>
            </React.Fragment>
          ))}
        </Grid>
      </AppLayout>
      <FooterDiv>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column className="image" width={9}>
              <Image
                src={`${process.env.GATSBY_CDN_ROOT}/images/app/dashboard-devices.webp`}
              />
            </Grid.Column>
            <Grid.Column
              textAlign={isMobile ? 'center' : 'left'}
              verticalAlign="middle"
              width={7}
              className="up-mobile"
            >
              <div className="message">
                <h3 style={{ marginBottom: '40px' }}>
                  Log in to your dashboard to upload your information and get
                  the most out of your call{' '}
                </h3>
                {loginButton}
              </div>
            </Grid.Column>
            <Grid.Column
              textAlign={isMobile ? 'center' : 'left'}
              verticalAlign="middle"
              width={7}
              className="only-mobile"
            >
              <h4>
                Log in to your dashboard to upload your information and get the
                most out of your call{' '}
              </h4>
              {loginButton}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </FooterDiv>
    </>
  )
}

export default DesignerThankYouPage

const AdviceRow = styled(Grid.Row)`
  &&&& {
    margin-bottom: 80px;
  }
`

const FooterDiv = styled.section`
  &&&&& {
    background: ${Colors.gray100};
    flex-grow: 1;
    padding: 0;

    @media ${Breakpoint.onlyMobile} {
      padding-bottom: 100px;
    }

    .column.image {
      padding: 0 !important;
    }

    .row {
      padding: 0;
    }

    .message {
      display: flex;
      flex-direction: column;
      margin: 0 0 30px 30px;
      width: 80%;
    }

    h4 {
      margin: 20px 0 40px;
    }
  }
`

const TestimonialDiv = styled.div`
  div {
    text-align: left;

    @media ${Breakpoint.onlyMobile} {
      margin-top: 32px;
      width: 90%;
    }
  }

  @media ${Breakpoint.upFromMobile} {
    align-items: center;
    column-gap: 50px;
    display: flex;
    flex-direction: row;
    padding-top: 30;

    img.image {
      max-width: 60%;
    }
  }
`
