import React, { useContext, useEffect } from 'react'

import { Container, Grid } from 'semantic-ui-react'

import fireEvent from 'context/tracking/events'
import SelectTextCard from 'design-system/components/cards/selection/select-text-card'
import { QuizAnswerCategory } from 'types/prospect'

import { QuizContext } from '../../index'
import { OptionGridColumn } from '../../utils'
import { StepViewProps } from '../steps'

const YourProjectView = ({ setNextDisabled, setOnNext }: StepViewProps) => {
  const { dispatch, state, motivationAnswer } = useContext(QuizContext)

  const onChange = (value: string) => {
    dispatch({
      type: 'toggle_answer',
      answer: { category: QuizAnswerCategory.MOTIVATION, value },
    })
  }

  useEffect(() => {
    document.body.scrollTo({ top: 0 })
    window.scrollTo(0, 0)
    const onNext = async () => {
      fireEvent({
        type: 'quiz_step_completed',
        stepName: 'MOTIVATION',
      })
    }
    setOnNext(() => onNext)
  }, [])

  useEffect(() => {
    setNextDisabled(!motivationAnswer?.length)
  }, [state, motivationAnswer])

  return (
    <>
      <Container>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column textAlign="center">
              <h2>What’s driving the project?</h2>
              <p className="light-gray">Select all that apply.</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <OptionGridColumn computer={7} tablet={10} mobile={16}>
              {motivationOptions.map((o, idx) => (
                <SelectTextCard
                  key={idx}
                  onClick={() => onChange(o.value)}
                  selected={
                    !!motivationAnswer?.find((a) => a.value === o.value)
                  }
                  text={o.text}
                />
              ))}
            </OptionGridColumn>
          </Grid.Row>
        </Grid>
      </Container>
    </>
  )
}

export default YourProjectView

const motivationOptions = [
  {
    text: 'I just moved into a new house',
    selected: false,
    value: 'I just moved into a new house',
  },
  {
    text: 'My current kitchen is tired',
    selected: false,
    value: 'My current kitchen is tired',
  },
  {
    text: 'Part of a bigger renovation',
    selected: false,
    value: 'Part of a bigger renovation',
  },
  {
    text: 'I’m building a new home',
    selected: false,
    value: 'I’m building a new home',
  },
  {
    text: 'Something else',
    selected: false,
    value: 'Something else',
  },
]
