import React, { useContext, useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { isEqual } from 'lodash'

import LeadTimeProvider from 'context/lead-times/provider'
import { validateAffiliateCode } from 'context/project/utils-discounts'
import useProspect from 'context/prospect/use'
import useProspectMutateNoContext from 'context/prospect/use-mutate-no-context'
import {
  getShareASaleClickId,
  TrackingContext,
} from 'context/tracking/provider'
import SaveToast from 'design-system/components/save-toast/save-toast'
import AppLayout from 'design-system/containers/app-layout'
import { FormContactSource } from 'types/crm'
import { Prospect, ProspectType } from 'types/prospect'

import ContactInformationForm from './contact-information-form'

const ContactInformation = (_: RouteComponentProps) => {
  const { prospect } = useProspect()
  const { state: utmState } = useContext(TrackingContext)
  const { upsertProspect } = useProspectMutateNoContext()
  const initialState = {
    firstName: prospect?.firstName ?? '',
    lastName: prospect?.lastName ?? '',
    email: prospect?.email ?? '',
    zipCode: prospect?.zipCode ?? '',
    data: {
      userType: prospect?.data?.userType ?? undefined,
    },
  }
  const [state, setState] = useState<Partial<Prospect>>(initialState)
  const [loading, setLoading] = useState(false)

  const onSave = async () => {
    setLoading(true)
    const newProspect = await upsertProspect({
      variables: {
        data: {
          data: {
            ...validateAffiliateCode(utmState?.afmc || ''),
            form_contact_source: FormContactSource.TRADE,
            shareASaleTrackingCode: getShareASaleClickId(),
            userType: state.data?.userType,
            prospectType: ProspectType.TRADE,
          },
          dataTracking: {
            utmCampaign: utmState.utmccn,
            utmContent: utmState.utmcct,
            utmMedium: utmState.utmcmd,
            utmSource: utmState.utmcsr,
            utmTerm: utmState.utmctr,
            gclid: utmState.gclid,
          },
          email: state.email,
          firstName: state.firstName,
          lastName: state.lastName,
          zipCode: state.zipCode,
        },
      },
    })
    navigate(`/welcome/${newProspect.data?.upsertProspect.id}/trade`)
    setLoading(false)
  }

  return (
    <AppLayout>
      <h2 style={{ textAlign: 'center' }}>Contact Information</h2>
      <ContactInformationForm
        isEmailDisabled={true}
        setState={setState}
        state={state}
      />
      <SaveToast
        disabled={isEqual(state, initialState)}
        loading={loading}
        mainButtonText="Complete"
        message="Are your details complete?"
        onClick={onSave}
        show={!isEqual(state, initialState)}
      />
    </AppLayout>
  )
}

export default (_: RouteComponentProps) => {
  return (
    <LeadTimeProvider>
      <ContactInformation />
    </LeadTimeProvider>
  )
}
