import React, { useContext, useEffect, useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { Container, Form, Grid, Image } from 'semantic-ui-react'

import HelpMessage from 'components/shared/help-message'
import Loader from 'components/shared/loader'
import LeadTimeProvider from 'context/lead-times/provider'
import { TrackingContext } from 'context/tracking/provider'
import useUserMutateNoContext from 'context/user/use-mutate-no-context'
import useUserUniqueWithFiles from 'context/user/use-unique-files'
import { PhoneInput, validatePhone } from 'design-system/components/form'
import { StyledForm } from 'styles/admin/main'
import { User, UserTypeTrade } from 'types/user'
import { emailIsValid, getDropdownOptionsFromEnum } from 'views/utils'
import AffiliateBanner from 'views/welcome/prospect/affiliate-banner'
import { parseQueryString } from 'views/welcome/prospect/onboarding-homeowner/project-info/utils'
import { StepViewProps } from 'views/welcome/prospect/onboarding-trade/flow/steps'

type tradeProfileProps = RouteComponentProps & StepViewProps

const TradeProfileView = (props: tradeProfileProps) => {
  const { state: utmState } = useContext(TrackingContext)
  const { createUser } = useUserMutateNoContext()

  const parsed = parseQueryString(props.location?.search || '')
  const affiliateCode = parsed.affiliateCode || utmState.afmc
  const designerId = parsed.designerId
  const designerEmailPrefix = parsed.designer

  const { user: designer, loading: loadingDesigner } = useUserUniqueWithFiles(
    designerId,
    designerEmailPrefix && `${designerEmailPrefix}@formkitchens.com`,
  )

  const [inputError, setInputError] = useState<{
    commonPassword: boolean
    email: boolean
    password: boolean
    phone: boolean
  }>({
    commonPassword: false,
    email: false,
    password: false,
    phone: false,
  })
  const [state, setState] = useState<Partial<User>>({
    data: {
      passwordRequest: '',
    },
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    type: undefined,
  })

  useEffect(() => {
    if (
      state.firstName &&
      state.lastName &&
      state.email &&
      state.phone &&
      state.type &&
      !inputError.email &&
      !inputError.password &&
      !inputError.phone &&
      !inputError.commonPassword
    )
      props.setNextDisabled(false)
    else props.setNextDisabled(true)

    const onSubmit = async () => {
      props.setLoading(true)
      const newUser = await createUser({
        variables: {
          data: {
            company: { connect: { id: props.company?.id ?? '' } },
            data: {
              inTradeProgram: true,
              passwordRequest: state.data?.passwordRequest,
              ...((designerId || designerEmailPrefix) && {
                referredDesignerId: designer?.id,
              }),
            },
            email: state.email ?? '',
            firstName: state.firstName ?? undefined,
            lastName: state.lastName ?? '',
            phone: state.phone ?? '',
            type: state.type ?? undefined,
          },
        },
        onError: () => setInputError({ ...inputError, commonPassword: true }),
      })

      if (newUser?.data?.createOneUser?.id)
        navigate(`/welcome/trade-onboarding/thank-you`)

      props.setLoading(false)
    }

    props.setOnNext(() => onSubmit)
  }, [state, inputError])

  if ((designerId || designerEmailPrefix) && loadingDesigner) return <Loader />

  return (
    <>
      {affiliateCode && <AffiliateBanner affiliateCode={affiliateCode} />}
      <Container>
        <Grid centered>
          {!props.company?.id ? (
            <Grid.Row>
              <Grid.Column>
                <HelpMessage
                  icon="circle-info"
                  title="Looks like your company is already working with us"
                  message="Please email help@formkitchens.com so we can set you up with a project."
                />
              </Grid.Column>
            </Grid.Row>
          ) : (
            <>
              <Grid.Row>
                <Grid.Column textAlign="center">
                  {designer && (
                    <div>
                      <Image
                        avatar
                        circular
                        src={designer.picture}
                        style={{
                          height: '80px',
                          width: '80px',
                        }}
                      />
                    </div>
                  )}
                  <h2>Join our trade program</h2>
                  {designer ? (
                    <p>
                      Complete your profile to schedule a call with{' '}
                      {designer?.firstName}, your selected trade designer.
                    </p>
                  ) : (
                    <p>
                      Unlock access to high-end German cabinetry at competitive
                      prices, expert design collaboration, and streamlined
                      project management.
                    </p>
                  )}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column computer={11} tablet={16}>
                  <StyledForm>
                    <Form.Group widths="equal">
                      <Form.Input
                        label="First Name"
                        name="firstName"
                        onChange={(
                          _: React.ChangeEvent<HTMLInputElement>,
                          { value }: { value: string },
                        ) =>
                          setState({
                            ...state,
                            firstName: value,
                          })
                        }
                        placeholder="First Name"
                        required
                        value={state.firstName}
                      />
                      <Form.Input
                        label="Last Name"
                        name="lastName"
                        onChange={(
                          _: React.ChangeEvent<HTMLInputElement>,
                          { value }: { value: string },
                        ) =>
                          setState({
                            ...state,
                            lastName: value,
                          })
                        }
                        placeholder="Last Name"
                        required
                        value={state.lastName}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Input
                        {...(inputError.email && {
                          error: {
                            content: (
                              <p className="red small">
                                Please enter a valid email address
                              </p>
                            ),
                          },
                        })}
                        label="Company Email"
                        name="email"
                        type="email"
                        onChange={(
                          _: React.ChangeEvent<HTMLInputElement>,
                          { value }: { value: string },
                        ) => {
                          if (!emailIsValid(value))
                            setInputError({ ...inputError, email: true })
                          else setInputError({ ...inputError, email: false })
                          setState({
                            ...state,
                            email: value,
                          })
                        }}
                        placeholder="Company Email"
                        required
                        value={state.email}
                      />
                      <PhoneInput
                        {...(inputError.phone && {
                          error: {
                            content: (
                              <p className="red small">
                                Please enter a valid phone number
                              </p>
                            ),
                          },
                        })}
                        label="Phone"
                        name="phone"
                        onBlur={() =>
                          setInputError({
                            ...inputError,
                            phone: !validatePhone(state.phone),
                          })
                        }
                        onChange={(value) => {
                          if (!validatePhone(value))
                            setInputError({ ...inputError, phone: true })
                          else setInputError({ ...inputError, phone: false })
                          setState({
                            ...state,
                            phone: value,
                          })
                        }}
                        placeholder="Phone"
                        required
                        value={state.phone ?? ''}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Input
                        {...((inputError.password ||
                          inputError.commonPassword) && {
                          error: {
                            content: (
                              <p className="red small">
                                {inputError.commonPassword
                                  ? 'Password too common. Please mix upper/lower case letters and numbers.'
                                  : 'Password must have at least 8 characters'}
                              </p>
                            ),
                          },
                        })}
                        label="Password"
                        name="password"
                        type="password"
                        onChange={(
                          _: React.ChangeEvent<HTMLInputElement>,
                          { value }: { value: string },
                        ) => {
                          if (value.length < 8)
                            setInputError({
                              ...inputError,
                              commonPassword: false,
                              password: true,
                            })
                          else
                            setInputError({
                              ...inputError,
                              commonPassword: false,
                              password: false,
                            })
                          setState({
                            ...state,
                            data: {
                              passwordRequest: value,
                            },
                          })
                        }}
                        placeholder="Password"
                        required
                        value={state.data?.passwordRequest}
                      />
                      <Form.Select
                        label="Role"
                        onChange={(_, { value }) =>
                          setState({
                            ...state,
                            type: value as UserTypeTrade,
                          })
                        }
                        options={getDropdownOptionsFromEnum(UserTypeTrade)}
                        placeholder="Select a role"
                        required
                        value={state.type}
                      />
                    </Form.Group>
                  </StyledForm>
                </Grid.Column>
              </Grid.Row>
            </>
          )}
        </Grid>
      </Container>
    </>
  )
}

export default (props: StepViewProps) => {
  return (
    <LeadTimeProvider>
      <TradeProfileView {...props} />
    </LeadTimeProvider>
  )
}
